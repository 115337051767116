<template>
  <v-container>
    <h1 v-translate>
      Account
    </h1>

    <v-subheader
      v-translate
      class="pl-0"
    >
      Here is some basic information about your account.
    </v-subheader>

    <v-card
      max-width="400"
    >
      <v-card-title>
        {{ currentUser ? currentUser.fullName : '' }}
      </v-card-title>

      <v-card-subtitle>
        {{ currentUser.humanReadableRole }}
        <br>
        {{ currentUser ? currentUser.email : '' }}
      </v-card-subtitle>
    </v-card>

        
    <v-card
      max-width="400"
      class="mt-4"
    >
      <v-card-title v-translate>
        Password reset
      </v-card-title>

      <v-card-subtitle>
        <span
          v-translate
          translate-context="MUserSettingsPage password reset instructions"
        >
          A link will be sent to your registered email, that you can use to reset your password.
        </span>
      </v-card-subtitle>
      <v-card-text>
        <v-btn
          :disabled="$wait.is('processing')"
          :loading="$wait.is('processing')"
          color="primary"
          @click="resetPassword"
          block
        >
          <span
            v-translate
            translate-context="MUserSettingsPage component send button"
          >
            Request password reset link
          </span>
        </v-btn>
      </v-card-text>
    </v-card>

    <v-card
      max-width="400"
      class="mt-4"
    >
      <v-card-title v-translate>
        Language
      </v-card-title>

      <v-card-subtitle>
        <span
          v-translate
          translate-context="MUserSettingsPage language subtitle"
        >
          Select your preferred language. Note that the page will refresh upon change.
        </span>
      </v-card-subtitle>

      <v-card-text>
        <v-select
          :disabled="$wait.is('updating user lang')"
          :loading="$wait.is('updating user lang')"
          :value="$language.current"
          :items="availableLanguages"
          item-text="value"
          item-value="key"
          name="language"
          dense
          outlined
          hide-details
          :menu-props="{ offsetY: true }"
          @change="languageOnChange"
        />
      </v-card-text>
    </v-card>

    <v-card
      v-if="currentUser.gdprConsent"
      max-width="400"
      class="mt-4"
    >
      <v-card-title v-translate>
        Privacy statement
      </v-card-title>

      <v-card-text>
        <div
          class="d-flex align-start"
        >
          <v-icon
            color="success"
          >
            done
          </v-icon>

          <div
            class="ml-4"
          >
            <span v-translate>
              You have agreed to the use of your personal data according to the privacy statement
            </span>

            <div
              class="mt-4"
            >
              <a
                v-translate
                target="_blank"
                :href="gdprLink"
              >
                Open privacy statement
              </a>

              <v-icon
                class="ml-1"
                x-small
                style="vertical-align: text-top;"
              >
                open_in_new
              </v-icon>
            </div>
          </div>
        </div>

        <v-expansion-panels
          flat
          class="mt-4"
        >
          <v-expansion-panel>
            <v-expansion-panel-header
              class="px-2 py-0 caption-xs"
              style="min-height: 32px;"
            >
              <span v-translate>
                More information
              </span>
            </v-expansion-panel-header>

            <v-expansion-panel-content
              class="pa-2"
            >
              <div
                class="caption-xs"
                style="line-height: 16px;"
              >
                <span v-translate>
                  In compliance with the EU General Data Protection Regulation (GDPR), you have the
                  right to request for deletion of your personal data.
                </span>
                <br><br>
                <span v-translate>
                  Please discuss with your foreman on alternative ways to direct your work if
                  you decide to request for deletion of your personal data.
                </span>
                <br><br>
                <span v-translate>
                  To delete your personal data, please contact Mestamaster customer service.
                </span>
              </div>

              <v-btn
                outlined
                x-small
                class="ml-0 mt-4 mb-2"
                href="tel:+358 40 700 8296"
                color="primary"
              >
                <span v-translate>
                  Call
                </span>

                <v-icon
                  right
                  x-small
                >
                  phone
                </v-icon>
              </v-btn>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card-text>
    </v-card>

    <m-web-push-subscription
      class="mt-4"
    />

    <v-card
      max-width="400"
      class="mt-4"
    >
      <v-card-title v-translate>
        Analytics data collection
      </v-card-title>

      <v-card-subtitle v-translate>
        Help Mestamaster to help you even more.
      </v-card-subtitle>

      <v-card-text>
        <span v-translate>
          Mestamaster will use <strong>anonymous</strong> data to make better
          decisions about our product.
          Allow anonymous data collection so we can consider your needs 
          when planning the future of Mestamaster.
        </span>
        <v-switch
          :loading="$wait.is('updating tracking consent')"
          :input-value="currentUser.trackingConsent"
          :true-value="true"
          :false-value="false"
          :label="allowTrackingConsentLabel"
          color="success"
          :hint="trackingConsentHint"
          persistent-hint
          @change="updateTrackingConsent"
        />
      </v-card-text>
    </v-card>

    <v-card
      max-width="400"
      class="mt-4"
    >
      <v-card-title v-translate>
        Beta features
      </v-card-title>

      <v-card-subtitle v-translate>
        Be the first to use the newest Mestamaster features
      </v-card-subtitle>

      <v-card-text>
        <span v-translate>
          Try Mestamaster's newest features before everyone else. All new
          features will be clearly marked as "BETA", and you'll always be able
          to switch them back off.
        </span>

        <v-alert
          type="info"
          dense
          text
          border="left"
          class="caption mt-3"
        >
          <span v-translate>
            You will need to refresh your browser after changing this settings for changes to take effect!
          </span>
        </v-alert>

        <v-switch
          :loading="$wait.is('updating beta tester consent')"
          :input-value="currentUser.betaTester"
          :true-value="true"
          :false-value="false"
          :label="betaTesterConsentLabel"
          color="success"
          persistent-hint
          :hint="betaTesterConsentHint"
          @change="updateBetaTesting"
        />
      </v-card-text>
    </v-card>

    <v-card
      max-width="400"
      class="mt-4"
      v-if="(currentUser.role=='manager' || currentUser.role=='admin') && currentUser.betaTester"
    >
      <v-card-title v-translate>
        API Access
      </v-card-title>

      <v-card-subtitle v-translate>
        Access Mestamaster data through our API with your authentication token
      </v-card-subtitle>

      <v-card-text>
        <span v-translate>
          Do not share your token with anyone. It is your responsibility to keep it safe.
          Anyone with this token will be able to execute calls to Mestamaster on your behalf.
        </span>

        <v-text-field
            v-model="apiToken"
            :append-icon="showAPIToken ? 'visibility' : 'visibility_off'"
            readonly
            :type="showAPIToken ? 'text' : 'password'"
            @click:append="showAPIToken = !showAPIToken"
          ></v-text-field>
      </v-card-text>
    </v-card>

    <v-card
      max-width="400"
      class="mt-4"
    >
      <v-card-actions>
        <v-btn
          :loading="$wait.is('logging out')"
          :disabled="$wait.is('logging out')"
          color="error"
          text
          block
          @click="onLogout"
        >
          <span v-translate>
            Sign out
          </span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex';
  import { mapWaitingActions } from 'vue-wait';
  import MWebPushSubscription from '@/components/MWebPushSubscription';
  import currentUser from '../vuex/modules/currentUser';
  import {
    getAccessToken,
  } from '../vuex/utils'

  export default {
    components: {
      MWebPushSubscription,
    },

    computed: {
      ...mapGetters({
        currentUser: 'currentUser/user',
      }),

      availableLanguages() {
        const langs = [];
        Object.keys(this.$language.available).forEach((key) => {
          langs.push({ key, value: this.$language.available[key] });
        });

        return langs;
      },

      allowTrackingConsentLabel() {
        return this.$pgettext('Tracking consent toggle', 'Allow anonymous data collection');
      },

      betaTesterConsentLabel() {
        return this.$pgettext('Beta testing consent toggle', 'Enable beta features');
      },

      trackingConsentHint() {
        if (this.currentUser.trackingConsent) {
          return this.$pgettext('Tracking consent toggle', 'Enabled');
        }

        return this.$pgettext('Tracking consent toggle', 'Disabled');
      },

      betaTesterConsentHint() {
        if (this.currentUser.trackingConsent) {
          return this.$pgettext('Beta testing consent toggle', 'Beta features are enabled');
        }

        return this.$pgettext('Beta testing consent toggle', 'Beta features are disabled');
      },

      gdprLink() {
        const userLocale = this.$currentUser.lang || 'en';
        return `https://mestamaster.fi/${userLocale}/gdpr/`;
      },
    },

    data: () => ({
      showAPIToken: false,
      apiToken: getAccessToken(),
    }),

    methods: {
      ...mapActions({
        logout: 'currentUser/logout',
        updateUser: 'currentUser/updateUser',
        addNotification: 'snackbar/addNotification',
      }),

      ...mapWaitingActions('currentUser', {
        logout: 'logging out',
        sendPasswordReset: 'processing',
      }),


      async onLogout() {
        await this.logout();
        this.$router.replace({
          name: 'login',
        });
      },

      async updateTrackingConsent(trackingConsent) {
        this.$wait.start('updating tracking consent');

        const payload = {
          user: {
            trackingConsent,
          },
        };

        await this.updateUser(payload);

        this.$wait.end('updating tracking consent');
      },

      async updateBetaTesting(betaTester) {
        this.$wait.start('updating beta tester consent');

        const payload = {
          user: {
            betaTester,
          },
        };

        await this.updateUser(payload);

        this.$wait.end('updating beta tester consent');
      },

      async resetPassword() {
        const success = await this.sendPasswordReset({
          credential: this.currentUser.email,
          deliveryMethod: 'email',
        });

        if (!success) return;

        const msgs = {
          success: this.$pgettext('MForgotPassword component link sent message', 'Link sent'),
          fail: this.$pgettext('MForgotPassword component link sent message', 'Sending link failed'),
        };

        this.addNotification({
          msg: success ? msgs.success : msgs.fail,
          type: success ? 'success' : 'error',
        });
      },
      
      async languageOnChange(toLanguage) {
        this.$wait.start('updating user lang');

        this.$language.current = toLanguage;

        const payload = {
          user: {
            lang: toLanguage,
          },
        };

        await this.updateUser(payload);

        this.$nextTick(() => {
          window.location.reload();
        });
      },
    },
  };
</script>
